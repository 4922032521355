import React from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { FaExternalLinkAlt } from "react-icons/fa"

const nl2br = text => {
  return text.replace(/(?:\r\n|\r|\n)/g, "<br />")
}

const components = {
  p({ children }) {
    return (
      <p className="group">
        <a
          rel="noreferrer"
          className="block mt-1 text-gray-700 transition-all duration-300 ease-out group-hover:text-primary"
          target="_blank"
        >
          {children}
          <FaExternalLinkAlt className="inline ml-1 text-gray-400 group-hover:text-primary" />
        </a>
      </p>
    )
  },
}

const InfoCard = ({ info: { title, content } }) => {
  const isTitle = title === "相關報導"
  const info = React.createElement(
    ReactMarkdown,
    isTitle
      ? {
          className: "text-gray-700 break-words",
          components: components,
        }
      : {
          className: `text-gray-700`,
          rehypePlugins: [rehypeRaw],
        },
    isTitle ? content : nl2br(content)
  )
  return (
    <div className="container sm:flex mt-6 sm:mt-12">
      <div className="sm:w-1/4">
        <h3 className="title-border-sm font-serif text-xl mb-6">{title}</h3>
      </div>
      <div className="sm:w-3/4">{info}</div>
    </div>
  )
}

const InfoBlock = ({ infoList }) => {
  const infoBlock = infoList.map((info, index) => (
    <InfoCard info={info} key={index} />
  ))
  return <>{infoBlock}</>
}

components.p.propTypes = {
  children: PropTypes.any,
}
InfoCard.propTypes = {
  info: PropTypes.object,
}
InfoBlock.propTypes = {
  infoList: PropTypes.array,
}

export default InfoBlock
