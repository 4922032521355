import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const NextArrow = ({ sliderRef }) => {
  return (
    <div
      className="w-12 h-12 bg-white text-xl text-gray-500 items-center justify-center cursor-pointer rounded-full shadow-md absolute focus:outline-none right-0 top-0 -mr-3 mt-24 hidden transition-all duration-200 ease-linear lg:flex hover:text-gray-600"
      onClick={() => {
        sliderRef.current.slickNext()
      }}
    >
      <FaArrowRight>Next</FaArrowRight>
    </div>
  )
}

NextArrow.propTypes = {
  sliderRef: PropTypes.object,
}

const PrevArrow = ({ sliderRef }) => {
  return (
    <div
      className="w-12 h-12 bg-white text-xl text-gray-500 items-center justify-center cursor-pointer rounded-full shadow-md absolute focus:outline-none z-10 left-0 top-0 -ml-3 mt-24 hidden transition-all duration-200 ease-linear lg:flex hover:text-gray-600"
      onClick={() => {
        sliderRef.current.slickPrev()
      }}
    >
      <FaArrowLeft>Prev</FaArrowLeft>
    </div>
  )
}

PrevArrow.propTypes = {
  sliderRef: PropTypes.object,
}

const SliderItem = ({ lawyer: { name, position, profile, slug } }) => {
  return (
    <div className="px-3 focus:outline-none">
      <Link to={`/lawyer/${slug}`}>
        <div className="lawyer-image relative overflow-hidden ">
          <GatsbyImage alt={position} image={profile} loading="eager" />
          <div className="max-w-full absolute z-10 top-0 left-0 h-full w-full transform duration-500 hover:scale-110">
            <GatsbyImage alt={position} image={profile} loading="eager" />
          </div>
        </div>
      </Link>
      <div className="text-center mt-3">
        <h3 className="text-xl font-serif text-gray-700">{name}</h3>
        <p className="text-sm text-gray-500 mt-1">{position}</p>
      </div>
    </div>
  )
}

const LawyerSlider = ({ lawyers }) => {
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [lastSlide, setLastSlide] = useState(0)
  const [slideToShow, setSlideToShow] = useState(0)

  useEffect(() => {
    if (slideToShow === 2) {
      const _slider = sliderRef.current
      _slider.slickGoTo(1)
    }
  }, [slideToShow])

  const setInit = () => {
    const LengthOfSlideToShow = sliderRef.current.innerSlider.props.slidesToShow
    let last = lawyers.length - LengthOfSlideToShow
    setSlideToShow(LengthOfSlideToShow)
    setLastSlide(last)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: currentSlide,
    cssEase: "ease-in-out",
    arrows: true,
    centerMode: false,
    centerPadding: "32px",
    afterChange: current => {
      setCurrentSlide(current)
    },
    onReInit: setInit,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 639,
        settings: {
          centerMode: true,
          autoplaySpeed: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  }

  const sliderList = lawyers.map((lawyer, index) => (
    <SliderItem lawyer={lawyer} key={index} />
  ))

  return (
    <div className="lg:-mx-3 relative">
      <Slider {...settings} ref={sliderRef}>
        {sliderList}
      </Slider>
      {currentSlide === 0 ? "" : <PrevArrow sliderRef={sliderRef} />}
      {currentSlide < lastSlide && <NextArrow sliderRef={sliderRef} />}
    </div>
  )
}

export default LawyerSlider

SliderItem.propTypes = {
  lawyer: PropTypes.object,
}
LawyerSlider.propTypes = {
  lawyers: PropTypes.arrayOf(PropTypes.object),
}
