import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import LawyerSlider from "../components/templates/LawyerSlider"
import LogoZh from "../assets/img_zl-logo_logo-only.svg"
import { GatsbySeo } from "gatsby-plugin-next-seo/lib"
import useMetadata from "../hooks/useMetadata"
import LineButton from "../components/LineButton"
import PrimaryBtn from "../components/PrimaryButton"
import PropTypes from "prop-types"
import InfoBlock from "../components/templates/InfoBlock"
import { GatsbyImage } from "gatsby-plugin-image"

export const query = graphql`
  query MyQuery($slug: String!) {
    airtableLawyer(data: { Slug: { eq: $slug } }) {
      data {
        Name
        Introduction
        Experience
        Education
        Slug
        Profile {
          localFiles {
            childImageSharp {
              gatsbyImageData
            }
            url
          }
        }
        Position {
          data {
            Name
          }
        }
        News
        Practice_Area
        License_Number
      }
    }
    allAirtableLawyer(
      sort: { fields: rowIndex, order: ASC }
      filter: { data: { Slug: { ne: $slug } } }
    ) {
      nodes {
        data {
          Name
          Slug
          Profile {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 550, placeholder: BLURRED)
              }
            }
          }
          Position {
            data {
              Name
            }
          }
        }
      }
    }
  }
`
export default function Lawyer({
  data: {
    airtableLawyer: {
      data: {
        Name: name,
        Introduction: intro,
        Experience: experience,
        Education: education,
        Slug: slug,
        Profile: profile,
        Position: position,
        News: personalCase,
        Practice_Area: practiceAreas,
        License_Number: licenseNumber,
      },
    },
    allAirtableLawyer: { nodes },
  },
}) {
  const lawyers = nodes.map(node => {
    return {
      name: node.data.Name,
      position: node.data.Position[0].data.Name,
      profile: node.data.Profile.localFiles[0].childImageSharp.gatsbyImageData,
      slug: node.data.Slug,
    }
  })
  const { siteUrl } = useMetadata()
  const metaTitle = name
  const metaDescription = `喆律法律事務所,${intro}`
  const ogImageFixed = profile?.localFiles[0].url
  const ogImageAlt = metaTitle
  const canonical = `${siteUrl}/lawyer/${slug}`

  const infoList = [
    {
      title: "律師介紹",
      content: intro,
    },
    {
      title: "學經歷",
      content: education + "\n\n" + experience,
    },
    {
      title: "專長",
      content: practiceAreas,
    },
    {
      title: "相關報導",
      content: personalCase,
    },
  ]

  return (
    <Layout transParentMenu={false}>
      <GatsbySeo
        title={metaTitle}
        description={metaDescription}
        canonical={canonical}
        openGraph={{
          title: metaTitle,
          description: metaDescription,
          url: canonical,
          images: [
            {
              url: ogImageFixed,
              alt: ogImageAlt,
            },
          ],
          type: "website",
        }}
      />
      <div className="mt-nav pb-48 bg-gray-200 lg:pt-12">
        <div className="lg:container lg:table lg:items-center lg:mx-auto">
          <div className="lg:table-cell lg:align-middle lg:w-7/12">
            <GatsbyImage
              alt={metaTitle}
              image={profile.localFiles[0].childImageSharp.gatsbyImageData}
            />
          </div>
          <div className="lg:table-cell lg:align-middle lg:w-5/12 lg:relative">
            <div className="mobile:container mobile:mx-auto mobile:px-4 mobile:mt-6 lg:ml-12">
              <div className="w-full relative lg:static">
                <LogoZh className="absolute top-0 right-0 w-32 h-auto lg:right-auto lg:left:0 lg:w-64 lg:h-auto" />
              </div>
              <h2 className="text-2xl font-serif text-gray-700 sm:text-5xl">
                {name} {position[0].data.Name}
              </h2>
              <p className="text-sm font-serif text-gray-500 mt-2 sm:text-xl">
                {licenseNumber}
              </p>
              <div className="text-left mt-6 mb-6">
                <LineButton>
                  <PrimaryBtn size="normal">免費法律諮詢</PrimaryBtn>
                </LineButton>
              </div>
            </div>
          </div>
        </div>

        <InfoBlock infoList={infoList} />
      </div>
      <div className="pb-16 -mt-40">
        <div className="container">
          <h2 className="title-border">其他團隊成員</h2>
        </div>
        <div className="mt-8 lg:container lg:mx-auto">
          <LawyerSlider lawyers={lawyers} />
        </div>
      </div>
    </Layout>
  )
}

Lawyer.propTypes = {
  data: PropTypes.object,
}
